<div class="page-header container-fluid bg-primary d-flex flex-column align-items-center justify-content-center text-center p-5">
    <h1 class="display-3 text-uppercase mb-3">Servicios</h1>
    <div class="d-inline-flex text-white">
        <h6 class="text-uppercase m-0"><a class="text-white" routerLink="/">Inicio</a></h6>
        <h6 class="m-0 px-3">/</h6>
        <h6 class="text-uppercase m-0">Servicios</h6>
    </div>
</div>

<div class="container-fluid py-1 no-underline">
    <div class="container pt-5 pb-3">
        <h1 class="display-4 text-uppercase text-center mb-5">Nuestros Servicios</h1>
        <div class="row justify-content-center">
            <div class="col-lg-3 mb-2">
                <a routerLink="/individuals">
                    <div class="service-item rounded p-5 mb-4 d-flex flex-column justify-content-center align-items-center">
                        <i class="fa fa-3x fa-users text-primary mb-4"></i>
                        <h4 class="text-uppercase mb-4">Individuos</h4>
                        <p class="m-0 text-center services">Soluciones pensadas para vos</p>
                    </div>
                </a>
            </div>
            <div class="col-lg-3 mb-2">
                <a routerLink="/companies">
                    <div class="service-item rounded p-5 mb-4 d-flex flex-column justify-content-center align-items-center">
                        <i class="fa fa-3x fa-building text-primary mb-4"></i>
                        <h4 class="text-uppercase mb-4">Empresas</h4>
                        <p class="m-0 text-center services">Servicios y soluciones corporativas</p>
                    </div>
                </a>
            </div>
        </div>
    </div>
</div>