<div class="container">
    <div class="container-fluid py-5">
      <div class="container py-5">
        <h1 class="display-4 text-uppercase text-center mb-5" style="color: #252525;">Nuestro Equipo</h1>
        <div id="teamCarousel" class="carousel slide" data-ride="carousel">
          <div class="carousel-inner">
            <div class="carousel-item active">
              <div class="row">
                <div class="col-md-4" *ngFor="let item of teamMembers.slice(0, 3)">
                  <div class="team-item rounded overflow-hidden">
                    <div class="position-relative">
                      <img class="img-fluid w-100" [src]="item.image" [alt]="item.name">
                      <div class="team-overlay">
                        <div class="d-flex align-items-center justify-content-start">
                          <p style="color: #FBD541;" class="mx-3 text">{{ item.description }}</p>
                        </div>
                      </div>
                    </div>
                    <div class="bg-light text-center p-4">
                      <h4 class="text-uppercase" style="color: #444440;">{{ item.name }}</h4>
                      <p class="m-0" style="color: #444440;">{{ item.position1 }}</p>
                      <p class="m-0" style="color: #444440;">{{ item.position2 }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="carousel-item" *ngFor="let chunk of chunkArray(teamMembers.slice(3), 3)">
              <div class="row">
                <div class="col-md-4" *ngFor="let item of chunk">
                  <div class="team-item rounded overflow-hidden">
                    <div class="position-relative">
                      <img class="img-fluid w-100" [src]="item.image" [alt]="item.name">
                      <div class="team-overlay">
                        <div class="d-flex align-items-center justify-content-start">
                          <p style="color: #FBD541;" class="mx-3">{{ item.description }}</p>
                        </div>
                      </div>
                    </div>
                    <div class="bg-light text-center p-4">
                      <h4 class="text-uppercase" style="color: #444440;">{{ item.name }}</h4>
                      <p class="m-0" style="color: #444440;">{{ item.position1 }}</p>
                      <p class="m-0" style="color: #444440;">{{ item.position2 }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <a class="carousel-control-prev" href="#" role="button" data-bs-target="#teamCarousel" data-bs-slide="prev" (click)="preventDefault($event)">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Previous</span>
          </a>
          <a class="carousel-control-next" href="#" role="button" data-bs-target="#teamCarousel" data-bs-slide="next" (click)="preventDefault($event)">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Next</span>
          </a>
        </div>
      </div>
    </div>
  </div>
  