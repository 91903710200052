import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ICourseDetailDTO } from '../../core/interfaces/course-detail.interface';
import { CoursesService } from '../../core/services/courses.service';

@Component({
  selector: 'app-course-detail',
  templateUrl: './course-detail.component.html',
  styleUrls: ['./course-detail.component.css'],
})
export class CourseDetailComponent implements OnInit {
  course: ICourseDetailDTO;
  paragraphs: string[];
  
  constructor(
    private activatedRoute: ActivatedRoute,
    private coursesService: CoursesService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe((params) => {
      const id = Number(params.get('id'));
      if (id) {
        this.loadCourse(id);
      }
    });
  }

  private loadCourse(id: number) {
    this.coursesService.getCourseById(id).subscribe((course) => {
      this.course = course;
      if (this.course && this.course.content) {
        console.log(this.course.content);
        this.paragraphs = this.course.content.split('\\n\\n');
      }
    });
  }

  goToPayment() {
    if (this.course) {
      this.router.navigate(['/payment', this.course.id]);
    }
  }

}
