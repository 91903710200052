<div class="main-back">
  <div class="container m-auto bg-white p-5 bod-3">
    <div class="row">
      <!-- CARD FORM -->
      <div class="col-lg-8 col-md-12">
        <form [formGroup]="paymentForm">
          <div class="header flex-between flex-vertical-center">
            <div class="flex-vertical-center">
              <span class="title">
                <img
                  src="assets/img/logos/main_logo.png"
                  height="50"
                  class="credit-card-image my-3"
                  id="credit-card-image"
                />
              </span>
            </div>
          </div>
          <div class="card-data flex-fill flex-vertical">
            <!-- Name -->
            <!-- Name -->
            <div class="flex-between">
              <div class="card-property-title">
                <strong>Nombre y Apellido</strong>
              </div>
              <div class="card-property-value">
                <div
                  class="input-container"
                  [ngClass]="{
                    invalid:
                      paymentForm.get('name')?.invalid &&
                      paymentForm.get('name')?.touched
                  }"
                >
                  <input
                    formControlName="name"
                    type="text"
                    class="uppercase"
                    placeholder="Nombre y Apellido"
                  />
                  <i class="ai-person"></i>
                </div>
                <div
                  *ngIf="
                    paymentForm.get('name')?.invalid &&
                    paymentForm.get('name')?.touched
                  "
                  class="error-message"
                >
                  El nombre y apellido son obligatorios.
                </div>
              </div>
            </div>

            <!-- E-mail address -->
            <div class="flex-between">
              <div class="card-property-title">
                <strong>E-Mail</strong>
              </div>
              <div class="card-property-value">
                <div
                  class="input-container"
                  [ngClass]="{
                    invalid:
                      paymentForm.get('email')?.invalid &&
                      paymentForm.get('email')?.touched
                  }"
                >
                  <input
                    formControlName="email"
                    type="email"
                    class="uppercase"
                    placeholder="E-MAIL"
                  />
                  <i class="ai-person"></i>
                </div>
                <div
                  *ngIf="
                    paymentForm.get('email')?.invalid &&
                    paymentForm.get('email')?.touched
                  "
                  class="error-message"
                >
                  Debe ingresar un correo electrónico válido.
                </div>
              </div>
            </div>

            <!-- Address -->
            <div class="flex-between">
              <div class="card-property-title">
                <strong>Dirección</strong>
              </div>
              <div class="card-property-value">
                <div class="input-container">
                  <input
                    formControlName="address"
                    type="text"
                    class="uppercase"
                    placeholder="DIRECCIÓN"
                  />
                  <i class="ai-person"></i>
                </div>
              </div>
            </div>

            <!-- Location -->
            <div class="flex-between">
              <div class="card-property-title">
                <strong>Localidad</strong>
              </div>
              <div class="card-property-value">
                <div class="input-container">
                  <input
                    formControlName="locality"
                    type="text"
                    class="uppercase"
                    placeholder="LOCALIDAD"
                  />
                  <i class="ai-person"></i>
                </div>
              </div>
            </div>

            <!-- Province -->
            <div class="flex-between">
              <div class="card-property-title">
                <strong>Provincia</strong>
              </div>
              <div class="card-property-value">
                <div class="input-container">
                  <input
                    formControlName="province"
                    type="text"
                    class="uppercase"
                    placeholder="PROVINCIA"
                  />
                  <i class="ai-person"></i>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>

      <!-- SIDEBAR -->
      <div class="col-lg-4 col-md-12 py-3">
        <div class="purchase-section flex-fill flex-vertical">
          <div class="card-mockup flex-vertical">
            <img
              id="imagenCurso"
              src="{{ course.backgroundImage }}"
              class="full-width"
            />
          </div>

          <ul class="purchase-props">
            <li class="flex-between">
              <span>Curso</span>
              <strong>{{ course.title }}</strong>
            </li>
            <li class="flex-between">
              <span>Subtotal</span>
              <strong>${{ course.price }}</strong>
            </li>
          </ul>
        </div>
        <div class="separation-line"></div>
        <div class="total-section flex-between flex-vertical-center">
          <div class="flex-fill flex-vertical">
            <div class="total-label f-secondary-color">TOTAL</div>
            <div>
              <strong>${{ course.price }}</strong>
            </div>
          </div>
          <i class="ai-coin size-lg"></i>
        </div>
        <div class="total-section">
          <div class="action flex-center">
            @if(showPayButton){
                <button class="b-main-color pointer"
                id="checkout" 
                (click)="pay(course.id)"
                [ngClass]="{ 'button-disabled': !paymentForm.get('name')?.valid || !paymentForm.get('email')?.valid }"
                [disabled]="!paymentForm.get('name')?.valid || !paymentForm.get('email')?.valid">Continuar con el pago</button>
            }
            <div id="wallet_container"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
