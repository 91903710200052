<div class="bg">
  <!-- Mostrar la tarjeta de éxito si existe merchant_order_id -->
  <div *ngIf="!isError; else errorTemplate" class="card mb-5">
      <span class="card__success"><i class="ion-checkmark"></i></span>
      <h1 class="card__msg">Procesando pago</h1>
      <h2 class="card__submsg mb-5 mt-3">Gracias por tu compra</h2>
    
      <div class="card__body">
          <div class="card__recipient-info">
              <p class="card__recipient">Orden N°: {{ merchantOrderId }}</p>
              <p class="card__recipient">Una vez confirmado el pago, recibirás un mail con los datos para poder acceder al curso.</p>
              <p class="card__recipient">Por cualquier inconveniente puedes contactarnos a</p>
              <p class="card__email">contacto&#64;lightcapgroup.com.ar</p>
          </div>
      </div>   
  </div>

  <!-- Plantilla de error -->
  <ng-template #errorTemplate>
      <div class="card mb-5">
          <span class="card__error"><i class="ion-alert"></i></span>
          <h1 class="card__msg">¡Ops! Algo salió mal</h1>
          <h2 class="card__submsg mb-5 mt-3">No pudimos procesar tu pago.</h2>
          <p class="card__recipient">Por favor, intenta nuevamente o contáctanos para más ayuda.</p>
          <p class="card__email">contacto&#64;lightcapgroup.com.ar</p>
      </div>
  </ng-template>
</div>
