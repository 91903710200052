<div *ngIf="course">
  <section
    class="banner_main"
    [ngStyle]="{ 'background-image': 'url(' + course.backgroundImage + ')' }"
  >
    <div class="container-fluid">
      <div class="row d_flex">
        <div class="col-md-7">
          <div class="text-bg">
            <div class="px-5">
              <h1>{{ course.title }}</h1>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <div id="services" class="service">
    <div class="container-fluid">
      <div class="row d_flex">
        <div class="col-md-7">
          <div class="titlepage">
            <h2>Sobre el curso</h2>
            @if(paragraphs && paragraphs.length > 0){
              <span>
                @for (paragraph of paragraphs; track $index) {
                  <p> {{ paragraph }}</p>
                }          
              </span>
            }
            @else {
              <p>No hay contenido disponible.</p>
            }
          </div>
        </div>
        <div class="col-md-5 py-5">
          <div class="titlepage">
            <div style="display: inline-block">
              <h6 style="display: inline; margin-right: 5px">Duración:</h6>
              <p style="display: inline">{{ course.duration }} minutos</p>
            </div>
            <br />
            <div style="display: inline-block">
              <h6 style="display: inline; margin-right: 5px">Modalidad:</h6>
              <p style="display: inline">{{ course.modeDescription }}</p>
            </div>
            <br />
            <div style="display: inline-block">
              <h6 style="display: inline; margin-right: 5px">Nivel:</h6>
              <p style="display: inline">{{ course.levelDescription }}</p>
            </div>
            <br />
            @if(course.paymentMode === 2){
            <div style="display: inline-block">
              <h6 style="display: inline; margin-right: 5px">Valor:</h6>
              <p style="display: inline">${{ course.price }}</p>
            </div>
            <br />         
            <div>
              <button mat-fab extended class="buy" (click)="goToPayment()">Comprar</button>
            </div>
          }
          </div>  
        </div>
      </div>
    </div>
  </div>
  
  @if(course.modules.length != 0){
    <div class="container-fluid py-5 service">
      <div class="container pt-5 pb-3">
        <h1 class="display-4 text-uppercase text-center mb-5">Módulos</h1>
        <div class="row justify-content-center">
          @for (module of course.modules; track $index; let idx = $index) {
            <div class="col-12 col-sm-6 col-md-4 col-lg-3 mb-3"> <!-- Ajusta aquí -->
              <mat-card class="module-card">
                <mat-card-title class="circle">{{ idx + 1 }}</mat-card-title>
                <mat-card-content class="module-content">
                  {{ module.title }}
                </mat-card-content>
              </mat-card>
            </div>
          }
        </div>
      </div>
    </div>
  }  
  @if(course.paymentMode === 2){
    <div class="container-fluid service">
      <div class="container pb-5 d-flex justify-content-center">
          <button mat-fab extended class="buy" (click)="goToPayment()">
            Comprar
          </button>
      </div>
    </div>
  } @else {
    <div class="container-fluid service">
      <div class="container pb-5 d-flex justify-content-center">
        <app-video [videoId]="course.id.toString()"></app-video>
      </div>
    </div>
  }
  
