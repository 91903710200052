import { Component, OnInit } from '@angular/core';
import { Course } from '../../core/interfaces/course.interface';
import { CoursesService } from '../../core/services/courses.service';
import { Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';

const youtube_icon = `
      <svg height="25px" width="25px" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 461.001 461.001" xml:space="preserve">
          <g>
            <path style="fill:#F61C0D;"
              d="M365.257,67.393H95.744C42.866,67.393,0,110.259,0,163.137v134.728 c0,52.878,42.866,95.744,95.744,95.744h269.513c52.878,0,95.744-42.866,95.744-95.744V163.137 C461.001,110.259,418.135,67.393,365.257,67.393z M300.506,237.056l-126.06,60.123c-3.359,1.602-7.239-0.847-7.239-4.568V168.607 c0-3.774,3.982-6.22,7.348-4.514l126.06,63.881C304.363,229.873,304.298,235.248,300.506,237.056z" />
          </g>
    </svg>
`;

interface CarouselCourse {
  id: number;
  title: string;
  description: string;
  id_courseType: number;
  price: number;
  image: string;
  content?: string;
}

@Component({
  selector: 'app-courses',
  templateUrl: './courses.component.html',
  styleUrls: ['./courses.component.css'],
})
export class CoursesComponent implements OnInit {
  freeCourses: CarouselCourse[] = [];
  paidCourses_market: CarouselCourse[] = [];
  paidCourses_finance: CarouselCourse[] = [];

  responsiveOptions = [
    {
      breakpoint: '1440px',
      numVisible: 3,
      numScroll: 3,
    },
    {
      breakpoint: '1200px',
      numVisible: 2,
      numScroll: 2,
    },
    {
      breakpoint: '768px',
      numVisible: 1,
      numScroll: 1,
    },
  ];

  data: Course[] = [];

  constructor(
    private coursesService: CoursesService,
    private router: Router,
    iconRegistry: MatIconRegistry,
    sanitizer: DomSanitizer
  ) {
    iconRegistry.addSvgIconLiteral(
      'youtube_icon',
      sanitizer.bypassSecurityTrustHtml(youtube_icon)
    );
  }

  ngOnInit() {
    this.coursesService.getCourses().subscribe((x) => {
      this.data = x;

      // Filtrar los cursos con id_courseType igual a 1 (gratuitos)
      this.freeCourses = this.data
        .filter((course) => course.courseType.id === 1)
        .map((course) => ({
          id: course.id,
          title: course.title,
          id_courseType: course.courseType.id, // Acceso al id dentro del objeto courseType
          description: course.description || 'No description available',
          price: course.price || 0,
          image: course.background_img || 'default-image.jpg',
          content: course.content || 'No description available',
        }));
      // Filtrar los cursos con id_courseType igual a 2 (pagos)
      this.paidCourses_market = this.data
        .filter((course) => course.courseType.id === 2)
        .filter((course) => course.topic.id === 2)
        .map((course) => ({
          id: course.id,
          title: course.title,
          id_courseType: course.courseType.id, // Acceso al id dentro del objeto courseType
          description: course.description || 'No description available',
          price: course.price || 0,
          image: course.background_img || 'default-image.jpg',
          content: course.content || 'No description available',
        }));
      this.paidCourses_finance = this.data
        .filter((course) => course.courseType.id === 2)
        .filter((course) => course.topic.id === 1)
        .map((course) => ({
          id: course.id,
          title: course.title,
          id_courseType: course.courseType.id, // Acceso al id dentro del objeto courseType
          description: course.description || 'No description available',
          price: course.price || 0,
          image: course.background_img || 'default-image.jpg',
          content: course.content || 'No description available',
        }));
    });
  }

  go(course: CarouselCourse) {
    this.router.navigate([`/courses/${course.id}`]);
  }
}
