<div class="page-header container-fluid bg-primary d-flex flex-column align-items-center justify-content-center text-center p-5">
  <h1 class="display-3 text-uppercase mb-3">Capacitación en Finanzas</h1>
  <div class="d-inline-flex text-white">
      <h6 class="text-uppercase m-0"><a class="text-white" routerLink="/">Inicio</a></h6>
      <h6 class="m-0 px-3">/</h6>
      <h6 class="text-uppercase m-0">
      <a class="text-white" routerLink="/services">Servicios</a>
    </h6>
    <h6 class="m-0 px-3">/</h6>
    <h6 class="text-uppercase m-0">Capacitación en Finanzas</h6>
  </div>
</div>

<div class="container-fluid py-1">
  <div class="container pt-5 pb-3">
    <p class="text-center mb-5" style="font-size: xx-large">
      Te acompañamos en tu camino de formación en finanzas desde los primeros
      pasos, para qué te sientas seguro en la planificación de tu futuro y el
      uso de herramientas del Mercado de Capitales. Nuestras propuestas incluyen
      contenido gratuito, cursos en vivo y asincrónicos, y packs de cursos con
      asesoría uno a uno.
    </p>
    <div class="row">
      <div class="col-12 text-center mb-2">
        <ul class="list-inline mb-4 d-flex justify-content-center">
          <li class="list-inline-item">
            <button
              mat-fab
              extended
              class="custom-fab-button"
              [routerLink]="[]"
              [fragment]="'pago'"
            >
              <mat-icon class="custom-icon">shopping_cart</mat-icon>
              Cursos Pagos
            </button>
          </li>
          <li class="list-inline-item">
            <button
              mat-fab
              extended
              class="custom-fab-button"
              [routerLink]="[]"
              [fragment]="'gratis'"
            >
              <mat-icon class="custom-icon">cloud</mat-icon>
              Recursos Gratuitos
            </button>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>

<!-- Sección de recursos gratuitos -->
<section id="gratis">
  <div class="container-fluid py-1 mb-5">
    <div class="container pt-5 pb-3">
      <h1 class="display-4 text-uppercase text-center">Recursos Gratuitos</h1>
      <p class="mb-4" style="font-size: x-large; text-align: center">
        Nuestras charlas gratuitas, son el puntapié ideal para comenzar a
        organizar tus finanzas, ¡no te las pierdas!
      </p>
      <p-carousel
        [value]="freeCourses"
        [numVisible]="3"
        [numScroll]="3"
        [circular]="true"
        [responsiveOptions]="responsiveOptions"
      >
        <ng-template let-course pTemplate="item">
          <mat-card class="course-card" appearance="outlined">
            <img
              mat-card-image
              [src]="course.image"
              alt="Course Image"
              class="mb-3"
            />
            <mat-card-content>
              <p class="text-center title">{{ course.title }}</p>
              <p class="text-muted text-center description">
                {{ course.description }}
              </p>
            </mat-card-content>
            <mat-card-actions class="justify-content-center">
              <button mat-fab extended class="card-button" (click)="go(course)">
                <mat-icon>cloud</mat-icon>
                Ver ahora
              </button>
            </mat-card-actions>
          </mat-card>
        </ng-template>
      </p-carousel>
    </div>
    <p class="mb-3" style="font-size: x-large; text-align: center">
      Además podés descargarte estas herramientas:
    </p>
    <div class="container downloads">
      <table class="table table-borderless text-center">
        <tbody>
          <tr>
            <td class="d-flex justify-content-center align-items-end">
              <div class="download-item mx-2 text-center">
                <a href="assets/tools/Calculadora_ONs.xlsx" class="text-decoration-none download-link" download>
                  <i class="fa-solid fa-calculator fa-3x"></i>
                  <span>Calculadora de ONs</span>
                </a>
              </div>
              <div class="download-item mx-2 text-center">
                <a href="assets/tools/Presupuesto_personal.xlsx" class="text-decoration-none download-link" download>
                  <i class="fa-solid fa-piggy-bank fa-3x"></i>
                  <span>Presupuesto personal</span>
                </a>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</section>

<!-- Sección de pagos -->
<section id="pago">
  <div class="container-fluid py-1 mb-5">
    <div class="container pt-3 pb-1">
      <h1 class="display-4 text-uppercase text-center">Cursos Pagos</h1>
      <hr />
      <p class="mb-4" style="font-size: xx-large; text-align: center">
        <b>Finanzas Personales</b><br />
      </p>
      <p class="mb-4" style="font-size: x-large; text-align: center">
        ¡Toma el control de tus finanzas personales! En estos cursos vas a
        aprender a gestionar tus ingresos, planificar tus gastos, optimizar tus
        ahorros y comenzar a ordenarte para ir hacia tu objetivo, ¡manos a la
        obra!
      </p>
      <h3 class="display-4 text-uppercase text-center" id="pago">
        <p-carousel
        [value]="paidCourses_finance"
        [numVisible]="3"
        [numScroll]="3"
        [circular]="true"
        [responsiveOptions]="responsiveOptions"
      >
        <ng-template let-course pTemplate="item">
          <mat-card class="course-card" appearance="outlined">
            <img
              mat-card-image
              [src]="course.image"
              alt="Course Image"
              class="mb-3"
            />
            <mat-card-content>
              <p class="text-center title">{{ course.title }}</p>
              <p class="text-muted text-center description">
                {{ course.description }}
              </p>
              <p class="price text-muted text-center">
                <b>${{ course.price }}</b>
              </p>
            </mat-card-content>
            <mat-card-actions class="justify-content-center">
              <button mat-fab extended class="card-button" (click)="go(course)">
                Más info
              </button>
            </mat-card-actions>
          </mat-card>
        </ng-template>
      </p-carousel>
      </h3>
    </div>
    <hr />
    <div class="container pt-3 pb-3">
      <p class="mb-4" style="font-size: xx-large; text-align: center">
        <b>Herramientas del Mercado de Capitales</b>
      </p>
      <p class="mb-4" style="font-size: x-large; text-align: center">
        Las herramientas qué nos brinda el mercado de capitales nos permitirán
        avanzar hacia el objetivo maximizando los rendimientos y potenciando la
        estrategia, ¡comenzá ahora!
      </p>
      <p-carousel
        [value]="paidCourses_market"
        [numVisible]="3"
        [numScroll]="3"
        [circular]="true"
        [responsiveOptions]="responsiveOptions"
      >
        <ng-template let-course pTemplate="item">
          <mat-card class="course-card" appearance="outlined">
            <img
              mat-card-image
              [src]="course.image"
              alt="Course Image"
              class="mb-3"
            />
            <mat-card-content>
              <p class="text-center title">{{ course.title }}</p>
              <p class="text-muted text-center description">
                {{ course.description }}
              </p>
              <p class="price text-muted text-center">
                <b>${{ course.price }}</b>
              </p>
            </mat-card-content>
            <mat-card-actions class="justify-content-center">
              <button mat-fab extended class="card-button" (click)="go(course)">
                Más info
              </button>
            </mat-card-actions>
          </mat-card>
        </ng-template>
      </p-carousel>
    </div>
  </div>
</section>

<hr />
<p class="mb-4" style="font-size: xx-large; text-align: center">
  <b>Escucha también nuestro podcast y seguinos en Spotify </b>
</p>

<app-spotify-embed></app-spotify-embed>

<div
  class="container-fluid d-flex flex-column align-items-center justify-content-center mb-5 py-3"
>
  <a href="https://www.youtube.com/@LightCapGroup" target="_blank">
    <button mat-fab extended class="youtube-fab-button">
      <mat-icon svgIcon="youtube_icon" class="custom-icon"></mat-icon>
      Ir a nuestro canal de Youtube
    </button>
  </a>
</div>
