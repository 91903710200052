<div class="page-header container-fluid bg-primary d-flex flex-column align-items-center justify-content-center text-center p-5">
  <h1 class="display-3 text-uppercase mb-3">Contacto</h1>
  <div class="d-inline-flex text-white">
      <h6 class="text-uppercase m-0"><a class="text-white" routerLink="/">Inicio</a></h6>
      <h6 class="m-0 px-3">/</h6>
      <h6 class="text-uppercase m-0">Contacto</h6>
  </div>
</div>
<div style="margin-top: -200px;">
<app-contact-form></app-contact-form></div>

<app-network-contacts></app-network-contacts>