import { Component, HostListener, OnInit } from '@angular/core';
import { faMapMarkerAlt, faPhone, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faFacebookF, faLinkedinIn, faYoutube, faSpotify, faInstagram } from '@fortawesome/free-brands-svg-icons';
import { NewsletterService } from '../../../core/services/newsletter.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  
  faMapMarkerAlt = faMapMarkerAlt;
  faPhone = faPhone;
  faEnvelope = faEnvelope;

  faFacebookF = faFacebookF;
  faLinkedinIn = faLinkedinIn;
  faYoutube = faYoutube;
  faSpotify = faSpotify;
  faInstagram = faInstagram;

  menus: Menu[] = [
    { path: '/', label: 'Inicio', description: 'Página principal de LightCap Group' },
    { path: '/about', label: 'Sobre nosotros', description: 'Conoce más sobre nuestra empresa' },
    { path: '/courses', label: 'Cursos', description: 'Explora nuestros cursos disponibles' },
    { path: '/services', label: 'Servicios', description: 'Servicios que ofrecemos' },
    { path: '/contact', label: 'Contacto', description: 'Ponte en contacto con nosotros' }
  ];


  constructor(private newsletterService : NewsletterService, private snackBar: MatSnackBar) { }

  newsletterForm = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email]),
  });

  ngOnInit(): void { }
  
  onSubmit() {
    if (this.newsletterForm.valid) {
      const formData = {
        email: this.newsletterForm.get('email')?.value || ''
      };
      this.newsletterService.sendNewsletter(formData).subscribe({
        next: (response) => {
          this.snackBar.open(response.message, 'Cerrar', {
            duration: 3000, // duración en milisegundos
          });
        },
        error: (error) => {
          this.snackBar.open(error.error.message, 'Cerrar', {
            duration: 3000, // duración en milisegundos
          });
        }
      });
    }
  }
}


  
export interface Menu {
  path: string;
  label: string;
  description: string;
}
