import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AboutComponent } from './features/about/about.component';
import { ContactComponent } from './features/contact/contact.component';
import { HomeComponent } from './features/home/home.component';
import { CompaniesComponent } from './features/services/companies.component';
import { IndividualComponent } from './features/services/individual.component';
import { CoursesComponent } from './features/courses/courses.component';
import { CourseDetailComponent } from './features/courses/course-detail.component';
import { ServicesComponent } from './features/services/services.component';
import { PaymentComponent } from './features/payment/payment.component';
import { LoginComponent } from './features/login/login.component';
import { PaymentResultComponent } from './features/payment/payment-result/payment-result.component';
import { PageNotFoundComponent } from './features/page-not-found/page-not-found.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/home',
    pathMatch: 'full',
  },
  {
    path: 'home',
    component: HomeComponent,
  },
  {
    path: 'about',
    component: AboutComponent,
  },
  {
    path: 'courses',
    component: CoursesComponent,
  },
  {
    path: 'courses/:id',
    component: CourseDetailComponent,
  },
  {
    path: 'services',
    component: ServicesComponent,
  },
  {
    path: 'services/individual',
    component: IndividualComponent,
  },
  {
    path: 'services/companies',
    component: CompaniesComponent,
  },
  {
    path: 'contact',
    component: ContactComponent,
  },
  {
    path: 'payment/:id',
    component: PaymentComponent,
  },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'payment-result',
    component: PaymentResultComponent,
  },
  {
    path: '**',
    component: PageNotFoundComponent,
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
      anchorScrolling: 'enabled',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
