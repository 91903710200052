<div class="container-fluid py-5">
    <div class="container pb-3">
        <div class="row">
            <div class="col-lg-4 mb-2">
                <div class="d-flex align-items-center bg-light rounded mb-4 px-5" style="height: 150px;">
                    <div class="d-flex flex-column">
                        <h5 class="text-uppercase">Nuestras Redes</h5>
                        <div class="d-flex justify-content-start">
                            <a class="btn btn-lg btn-primary btn-lg-square mr-2" href="https://www.facebook.com/share/v16zoeDYbHt4Fu6M/?mibextid=qi2Omg"><i class="fab fa-facebook-f"></i></a>
                            <a class="btn btn-lg btn-primary btn-lg-square mr-2" href="https://www.linkedin.com/company/lightcap-group/"><i class="fab fa-linkedin-in"></i></a>
                            <a class="btn btn-lg btn-primary btn-lg-square mr-2" href="https://www.youtube.com/@LightCapGroup"><i class="fab fa-youtube"></i></a>
                            <a class="btn btn-lg btn-primary btn-lg-square mr-2" href="https://open.spotify.com/show/3dB7qiTqlJZuTJWXpB0uvb?si=3884ff23181f4838"><i class="fab fa-spotify"></i></a>  
                            <a class="btn btn-lg btn-primary btn-lg-square" href="https://www.instagram.com/lightcapgroup?utm_source=qr&igsh=dnFoYjV1Z29waDA0"><i class="fab fa-instagram"></i></a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 mb-2">
                <div class="d-flex align-items-center bg-light rounded mb-4 px-5" style="height: 150px;">
                    <i class="fa fa-3x fa-envelope-open text-primary mr-3"></i>
                    <div class="d-flex flex-column">
                        <h5 class="text-uppercase">E-mail</h5>
                        <p class="m-0">
                            <a href="mailto:contacto@lightcapgroup.com.ar">contacto&#64;lightcapgroup.com.ar</a>
                          </p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 mb-2">
                <div class="d-flex align-items-center bg-light rounded mb-4 px-5" style="height: 150px;">
                    <fa-icon [icon]="faWhatsapp" class="fa-3x text-primary mr-3"></fa-icon>
                    <div class="d-flex flex-column">
                        <h5 class="text-uppercase">Whatsapp</h5>
                        <p class="m-0">+54 (911) 2314-3797</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>