import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderComponent } from './components/header/header.component';
import { TeamComponent } from './components/team/team.component';
import { MaterialModule } from './material.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { CarouselModule } from 'primeng/carousel';
import { NetworkContactsComponent } from './components/network-contacts/network-contacts.component'; 
import { MatToolbarModule } from '@angular/material/toolbar'; // Importa MatToolbarModule
import { MatButtonModule } from '@angular/material/button';   // Importa MatButtonModule
import { MatMenuModule, MatMenuTrigger } from '@angular/material/menu';
import { SpotifyEmbedComponent } from './components/spotify-embed/spotify-embed.component';
import { ReactiveFormsModule } from '@angular/forms';
import { ContactFormComponent } from './components/contact-form/contact-form.component';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatListModule } from '@angular/material/list';
import { MenubarModule } from 'primeng/menubar';
import { ButtonModule } from 'primeng/button'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MenuModule } from 'primeng/menu';
import { MatDividerModule } from '@angular/material/divider';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { VideoComponent } from './components/video/video.component';
import { SafeUrlPipe } from './pipes/safe-url.pipe';

@NgModule({
  declarations: [HeaderComponent, FooterComponent, TeamComponent, NetworkContactsComponent, SpotifyEmbedComponent, ContactFormComponent,VideoComponent, SafeUrlPipe],
  imports: [CommonModule, MaterialModule, RouterModule,FontAwesomeModule, CarouselModule,MatToolbarModule, 
    MatButtonModule, MatMenuModule,ReactiveFormsModule, MatSidenavModule,  MatIconModule, FlexLayoutModule, MatListModule, MenubarModule, ButtonModule, BrowserAnimationsModule, MenuModule, MatToolbarModule,
    MatDividerModule, MatIconModule, MatMenuTrigger, MatSnackBarModule
    ],
  exports: [HeaderComponent, FooterComponent, TeamComponent, NetworkContactsComponent, SpotifyEmbedComponent, ContactFormComponent, VideoComponent, SafeUrlPipe],
})
export class SharedModule {}
