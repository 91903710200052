<div class="container-fluid bg-primary py-3 px-0">
  <div class="row mx-0 align-items-center">
    <div class="col-lg-6 px-md-5 text-center text-lg-left">
      <h2 class="display-2 text-uppercase mb-3 title">
        Hacemos de las finanzas algo simple
      </h2>
      <p class="text-dark mb-4" style="font-size: x-large">
        La educación financiera es parte del camino hacia tu libertad económica.
      </p>
      <a routerLink="/about" class="btn btn-dark text-uppercase mt-1 py-3 px-5" aria-label="Sobre nosotros."
        >Mas información</a
      >
    </div>
    <div class="col-lg-6 px-0 text-right">
      <picture>
        <source srcset="assets/7.webp" type="image/webp" />
        <img
          class="img-fluid mt-5 mt-lg-0"
          src="assets/7.png"
          alt="Descripción de la imagen"
        />
      </picture>
    </div>
  </div>
</div>
<div class="container-fluid py-5">
  <div class="container py-5">
    <div class="row align-items-stretch">
      <div class="col-lg-6 d-flex align-items-center">
        <img src="assets\8.png" class="img-fluid" alt="Image" />
      </div>
      <div class="col-lg-6 d-flex" style="background-color: #fbd541">
        <div class="my-auto">
          <h1 class="display-4 text-uppercase mb-4">Sobre nosotros</h1>
          <h5 class="mb-3" style="color: #252525">
            LightCap Group nace en respuesta a la necesidad de empoderar
            financieramente a todo aquel que quiera escucharnos, nuestra pasión
            por las finanzas y el mercado de capitales es el motor que nos
            impulsa a ofrecer un servicio de calidad focalizado en la
            experiencia y satisfacción del usuario.
          </h5>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="container-fluid py-1 no-underline">
  <div class="container pt-5 pb-3">
    <h1 class="display-4 text-uppercase text-center mb-5">
      Nuestros Servicios
    </h1>
    <div class="row justify-content-center">
      <div class="col-lg-3 mb-2">
        <a routerLink="../services/individual">
          <div
            class="service-item rounded p-5 mb-4 d-flex flex-column justify-content-center align-items-center"
          >
            <i class="fa fa-3x fa-users text-primary mb-4"></i>
            <h4 class="text-uppercase mb-4">Individuos</h4>
            <p class="m-0 text-center services">Soluciones pensadas para vos</p>
          </div>
        </a>
      </div>
      <div class="col-lg-3 mb-2">
        <a routerLink="../services/companies">
          <div
            class="service-item rounded p-5 mb-4 d-flex flex-column justify-content-center align-items-center"
          >
            <i class="fa fa-3x fa-building text-primary mb-4"></i>
            <h4 class="text-uppercase mb-4">Empresas</h4>
            <p class="m-0 text-center services">Servicios y soluciones corporativas</p>
          </div>
        </a>
      </div>
    </div>
  </div>
</div>
<app-team></app-team>
<div class="container-fluid py-1">
  <div class="container my-1">
    <h1 class="display-4 text-uppercase text-center">
      Comunicate con nosotros
    </h1>
  </div>
  <app-contact-form></app-contact-form>
</div>
