<mat-toolbar fxLayout="row">
  <a routerLink="/" class="navbar-brand text-secondary">
    <img
      src="assets/img/logos/main_logo.png"
      class="logo"
      alt="LightCap|Group"
      height="30px"
    />
  </a>

  <mat-toolbar fxLayout="row" fxLayoutAlign="end center">
    @for (menu of menus; track $index) {}
    <ng-container *ngFor="let menu of menus; trackBy: trackByFn; let i = index">
      <ng-container *ngIf="!menu.child">
        <button
          mat-button
          aria-label="menu.label"
          [fxShow]="menu.showOnDesktop"
          [fxShow.xs]="menu.showOnMobile"
          [fxShow.sm]="menu.showOnTablet"
          [routerLink]="menu.path"
        >
          {{ menu.label }}
        </button>
      </ng-container>
      <ng-container *ngIf="menu.child">
        <button
          mat-button
          [fxShow]="menu.showOnDesktop"
          [fxShow.xs]="menu.showOnMobile"
          [fxShow.sm]="menu.showOnTablet"
          [routerLink]="menu.path"
          [matMenuTriggerFor]="dropSubMenu"
        >
          {{ menu.label }}
        </button>
        <mat-menu #dropSubMenu="matMenu">
          <ng-container
            *ngTemplateOutlet="menuTemplate; context: { $implicit: menu.child }"
          ></ng-container>
        </mat-menu>
      </ng-container>
    </ng-container>

    <!-- Menú en versión móvil -->
    <ng-container *ngIf="isMobileView">
      <button mat-icon-button [matMenuTriggerFor]="dropMenu" aria-label="Menu">
        <mat-icon>menu</mat-icon>
      </button>
      <mat-menu #dropMenu="matMenu">
        <ng-container *ngFor="let menu of menus; trackBy: trackByFn">
          <ng-container *ngIf="!menu.child">
            <button
              mat-menu-item
              [routerLink]="menu.path"
              class="custom-button"
            >
              {{ menu.label }}
            </button>
          </ng-container>
          <ng-container *ngIf="menu.child">
            <button
              mat-menu-item
              [matMenuTriggerFor]="dropSubMenuMobile"
              class="custom-button"
            >
              {{ menu.label }}
            </button>
            <mat-menu #dropSubMenuMobile="matMenu">
              <ng-container
                *ngTemplateOutlet="
                  menuTemplate;
                  context: { $implicit: menu.child }
                "
              ></ng-container>
            </mat-menu>
          </ng-container>
        </ng-container>
      </mat-menu>
    </ng-container>
  </mat-toolbar>

  <!-- Plantilla para submenús -->
  <ng-template #menuTemplate let-submenus>
    <ng-container *ngFor="let submenu of submenus">
      <button mat-menu-item [routerLink]="submenu.path" class="custom-button">
        {{ submenu.label }}
      </button>
    </ng-container>
  </ng-template>
</mat-toolbar>
