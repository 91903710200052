import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class VideoService {

  private videoDataUrl = 'assets/videos.json';  // Ruta al archivo JSON

  constructor(private http: HttpClient) { }

  getVideos(): Observable<any[]> {
    return this.http.get<any[]>('assets/videos.json');
  }

  // Método para obtener los enlaces de video según el id
  getVideosById(videoId: string): Observable<string | null> {
    return this.getVideos().pipe(
      map(videos => {
        const video = videos.find(v => v.id === videoId);
        console.log(video)
        return video ? video.link : null;
      })
    );
  }
}
