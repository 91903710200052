import { Component } from '@angular/core';
import { ContactService } from '../../../core/services/contact.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-contact-form',
  templateUrl: './contact-form.component.html',
  styleUrl: './contact-form.component.css'
})
export class ContactFormComponent {
  subjectOptions = [
    { text: 'Cursos', value: '1' },
    { text: 'Asesoría', value: '2' },
    { text: 'Otros', value: '3' }
  ];

  isSubmitting = false;
  
  constructor(private contactService: ContactService, private snackBar: MatSnackBar) {}

  contactForm = new FormGroup({
    name: new FormControl('', [Validators.required]),
    email: new FormControl('', [Validators.required, Validators.email]),
    subject: new FormControl('', [Validators.required]),
    comment: new FormControl('', [Validators.required]),
  });

  onSubmit() {
    if (this.contactForm.valid) {
      this.isSubmitting = true;
      const object = this.contactForm.getRawValue();
      this.contactService.sendContact(object).subscribe({
        next: (response) => {
          this.snackBar.open(response.message, 'Cerrar', {
            duration: 3000, // duración en milisegundos
          });
        },
        error: (error) => {
          this.snackBar.open(error.error.message, 'Cerrar', {
            duration: 3000, // duración en milisegundos
          });
        }
      });
    }
  }
}
