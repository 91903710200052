<div class="page-header container-fluid bg-primary d-flex flex-column align-items-center justify-content-center text-center p-5">
  <h1 class="display-3 text-uppercase mb-3" id="title">Individuos</h1>
  <div class="d-inline-flex text-white">
    <h6 class="text-uppercase m-0">
      <a class="text-white" routerLink="/">Inicio</a>
    </h6>
    <h6 class="m-0 px-3">/</h6>
    <h6 class="text-uppercase m-0">
      <a routerLink="/services" style="color: #222429">Servicios</a>
    </h6>
    <h6 class="m-0 px-3">/</h6>
    <h6 class="text-uppercase m-0" id="title-content-course">Individuos</h6>
  </div>
</div>

<div class="container-fluid py-1">
  <div class="container pt-5 pb-1">
    <p class="text-center mb-5" style="font-size: xx-large">
      Sabemos qué las finanzas son una preocupación en tu vida, por lo qué
      ofrecemos servicios acorde a tus necesidades: ¡queremos acompañarte en tu
      crecimiento y la concreción de tus objetivos!
    </p>
  </div>
</div>
<div class="container-fluid py-5 no-underline">
  <div class="container pt-5 pb-3">
    <h1 class="display-4 text-uppercase text-center mb-5">
      Nuestros Servicios
    </h1>
    <div class="row justify-content-center">
      <div class="col-lg-6 mb-2 d-flex">
        <a routerLink="/courses" class="w-100">
          <div
            class="service-item rounded p-5 d-flex flex-column justify-content-start align-items-center"
          >
            <i class="fa fa-3x fa-book text-primary mb-4"></i>
            <h4 class="text-uppercase mb-4">Cursos</h4>
            <p class="m-0 text-center">
              Te acompañamos en tu camino de formación en finanzas desde los
              primeros pasos, para qué te sientas seguro en la planificación de
              tu futuro y el uso de herramientas del Mercado de Capitales.
              Nuestras propuestas incluyen contenido gratuito, cursos en vivo y
              asincrónicos, y packs de cursos con asesoría uno a uno.
            </p>
          </div>
        </a>
      </div>
      <div class="col-lg-6 mb-2 d-flex">
        <a routerLink="/contact" class="w-100">
          <div
            class="service-item rounded p-5 d-flex flex-column justify-content-start align-items-center"
          >
            <i class="fa fa-3x fa-building text-primary mb-4"></i>
            <h4 class="text-uppercase mb-4">Asesoría financiera</h4>
            <p class="m-0 text-center">
              Programamos un encuentro para conocer tus necesidades y armar una
              estrategia de acuerdo a tu perfil y tus objetivos.
            </p>
          </div>
        </a>
      </div>
    </div>
  </div>
</div>

