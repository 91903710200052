import { Component, AfterViewInit } from '@angular/core';

@Component({
  selector: 'app-spotify-embed',
  templateUrl: './spotify-embed.component.html',
  styleUrls: ['./spotify-embed.component.css']
})
export class SpotifyEmbedComponent {
  
}
