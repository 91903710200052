<footer>
  <div class="container-fluid bg-dark text-white-50 py-5 px-sm-3 px-md-5">
    <div class="row pt-5">
      <div class="col-lg-3 col-md-6 mb-5">
        <a href="index.html" class="navbar-brand">
          <img
            src="assets/img/logos/main_logo.png"
            class="img-fluid d-block mx-auto"
            alt="LightCap|Group"
          />
        </a>
        <h6 class="text-uppercase text-white py-2 text-center">
          Seguinos en nuestras redes
        </h6>
        <div class="d-flex justify-content-center">
          <a
            class="btn btn-lg btn-primary btn-lg-square mr-2 redes"
            href="https://www.facebook.com/share/v16zoeDYbHt4Fu6M/?mibextid=qi2Omg"
            aria-label="Seguinos en Facebook"
          >
            <fa-icon [icon]="faFacebookF"></fa-icon>
          </a>
          <a
            class="btn btn-lg btn-primary btn-lg-square mr-2 redes"
            href="https://www.linkedin.com/company/lightcap-group/"
            aria-label="Seguinos en Linkedin"
          >
            <fa-icon [icon]="faLinkedinIn"></fa-icon>
          </a>
          <a
            class="btn btn-lg btn-primary btn-lg-square mr-2 redes"
            href="https://www.youtube.com/@LightCapGroup"
            aria-label="Seguinos en Youtube"
          >
            <fa-icon [icon]="faYoutube"></fa-icon>
          </a>
          <a
            class="btn btn-lg btn-primary btn-lg-square mr-2 redes"
            href="https://open.spotify.com/show/3dB7qiTqlJZuTJWXpB0uvb?si=3884ff23181f4838"
            aria-label="Seguinos en Spotify"
          >
            <fa-icon [icon]="faSpotify"></fa-icon>
          </a>
          <a
            class="btn btn-lg btn-primary btn-lg-square redes"
            href="https://www.instagram.com/lightcapgroup?utm_source=qr&igsh=dnFoYjV1Z29waDA0"
            aria-label="Seguinos en Instagram"
          >
            <fa-icon [icon]="faInstagram"></fa-icon>
          </a>
        </div>
      </div>
      <div class="col-lg-3 col-md-6 mb-5">
        <h4 class="text-uppercase text-white mb-4">Contacto</h4>
        <p>
          <fa-icon [icon]="faMapMarkerAlt" class="text-white mr-2"></fa-icon
          >Buenos Aires, Argentina
        </p>
        <p>
          <fa-icon [icon]="faPhone" class="text-white mr-2"></fa-icon>+54 (911) 2314-3797
        </p>
        <p>
          <fa-icon [icon]="faEnvelope" class="text-white mr-2"></fa-icon
          ><a href="mailto:contacto@lightcapgroup.com.ar" class="text-white-50">contacto&#64;lightcapgroup.com.ar</a>
        </p>
      </div>
      <div class="col-lg-3 col-md-6 mb-5">
        <h4 class="text-uppercase text-white mb-4">Links de interés</h4>
        <div class="d-flex flex-column justify-content-start">
          <a *ngFor="let menu of menus" class="text-white-50 mb-2" [routerLink]="menu.path" [attr.aria-label]="menu.description">
            <i class="fa fa-angle-right text-white mr-2"></i>{{ menu.label }}
          </a>
        </div>
      </div>
      

      <div class="col-lg-3 col-md-6 mb-5">
        <h4 class="text-uppercase text-white mb-4">Newsletter</h4>
        <div class="w-100 mb-3">
          <form [formGroup]="newsletterForm" (ngSubmit)="onSubmit()">
            <div class="input-group">
              <input id="email" type="text" formControlName="email" class="form-control border-light" style="padding: 25px;" placeholder="E-mail" autocomplete="email">
              <div class="input-group-append">
                <button class="btn btn-primary text-uppercase px-3"
                        type="submit"
                        [disabled]="!newsletterForm.valid">Registrarse</button>
              </div>
            </div>
          </form>          
        </div>
      </div>
    </div>
  </div>
  <div
    class="container-fluid"
    style="
      background: #111111;
      display: grid;
      place-items: center;
      height: 15vh;
      padding-top: 2%;
    "
  >
    <a
      href="index.html"
      style="display: block; text-align: center; height: 100%; width: 100%"
    >
      <img
        src="assets/img/logos/main_logo.png"
        class="img-fluid"
        alt="LightCap|Group"
      />
    </a>
  </div>
</footer>

<a href="#" class="btn btn-lg btn-primary btn-lg-square back-to-top"
  ><i class="fa fa-angle-up"></i
></a>
<div zum-waypoint="waypoints" down="flags.on" up="flags.off"></div>
<div
  ng-class="{
    sticky: waypoints.flags.on,
    notSticky: waypoints.flags.off
  }"
></div>
