import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Course } from '../interfaces/course.interface';
import { Module } from '../interfaces/module.interface';
import { ICourseDetailDTO } from '../interfaces/course-detail.interface';
import { ICoursePaymentDTO } from '../interfaces/course-payment.interface';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CoursesService {

  // Corrección en la construcción de las URLs
  private apiUrl = `${environment.baseHref}/courses`;
  private moduleUrl = `${environment.baseHref}/module`;

  constructor(private http: HttpClient) {}

  getCourseByIdPayment(id: number): Observable<ICoursePaymentDTO> {
    return this.http.get<ICoursePaymentDTO>(`${this.apiUrl}/${id}`);
  }

  getCourseById(id: number): Observable<ICourseDetailDTO> {
    return this.http.get<ICourseDetailDTO>(`${this.apiUrl}/${id}`);
  }

  getCourses(): Observable<Course[]> {
    return this.http.get<Course[]>(this.apiUrl);
  }

  getModulesByCourseId(id: number): Observable<Module[]> {
    return this.http.get<Module[]>(`${this.moduleUrl}/${id}`);
  }
}
