import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { LoginService } from '../../core/services/login.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-login',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
  ],
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginComponent { 

  loginForm = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email]),
    password: new FormControl('', [Validators.required]),
  });

  constructor(
    private loginService: LoginService,
    private snackBar: MatSnackBar // Asegúrate de que MatSnackBar esté importado y configurado
  ) {}

  onSubmit() {
    if (this.loginForm.valid) {
      const formData = {
        email: this.loginForm.get('email')?.value || '',
        password: this.loginForm.get('password')?.value || ''
      };

      this.loginService.login(formData).subscribe({
        next: (response) => {
          this.snackBar.open('Login exitoso', 'Cerrar', {
            duration: 3000,
          });
          // Aquí podrías redirigir al usuario a otra página después de un login exitoso
        },
        error: (error) => {
          this.snackBar.open('Login fallido', 'Cerrar', {
            duration: 3000,
          });
        }
      });
    }
  }
}
