import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { VideoService } from '../../../core/services/video.service';

@Component({
    selector: 'app-video',
    templateUrl: './video.component.html',
    styleUrl: './video.component.css',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VideoComponent implements OnInit {
    @Input() videoId: string | null = null;  // Recibe el videoId como un input
    videoLink: string | null = null;   // Aquí guardaremos los enlaces de los videos
  
    constructor(private videoService: VideoService) {}
  
    ngOnInit(): void {
      if (this.videoId) {
        this.videoService.getVideosById(this.videoId).subscribe({
          next: (link: string | null) => {
            this.videoLink = link; // Se asigna el enlace si la respuesta es correcta
          },
          error: (err) => {
            console.error('Error al obtener el video:', err); // Muestra el error en la consola
          }
        });
      }
    }
  }
