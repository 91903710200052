<div class="page-header container-fluid bg-primary d-flex flex-column align-items-center justify-content-center text-center p-5">
    <h1 class="display-3 text-uppercase mb-3">Sobre Nosotros</h1>
  <div class="d-inline-flex text-white">
      <h6 class="text-uppercase m-0"><a class="text-white" routerLink="/">Inicio</a></h6>
      <h6 class="m-0 px-3">/</h6>
      <h6 class="text-uppercase m-0">Sobre Nosotros</h6>
  </div>
</div>

<div class="container-fluid pt-1">
  <div class="container pt-5">
      <div class="row align-items-center">
          <div class="col-lg-6">
              <img class="img-fluid mb-4 mb-lg-0" src="assets/img/about.jpg" alt="">
          </div>
          <div class="col-lg-6">
              <h1 class="display-4 text-uppercase mb-4">LightCap Group</h1>
              <h5 class="text-uppercase text-primary mb-3">LightCap Group nace en respuesta a la necesidad de empoderar financieramente a todo aquel que quiera escucharnos, nuestra pasión por las finanzas y el mercado de capitales es el motor que nos impulsa a ofrecer un servicio de calidad focalizado en la experiencia y satisfacción del usuario.</h5>
              <p class="mb-4" style="font-size: larger;">Fieles a nuestros valores de respeto, honestidad y responsabilidad, nuestra misión es acercar las finanzas como una herramienta para el crecimiento personal o de tu negocio en una propuesta dinámica y adecuada.</p>
          </div>
      </div>
  </div>
</div>

<app-team></app-team>