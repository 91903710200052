import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AboutComponent } from './features/about/about.component';
import { ContactComponent } from './features/contact/contact.component';
import { CoursesComponent } from './features/courses/courses.component';
import { HomeComponent } from './features/home/home.component';
import { MaterialModule } from './shared/material.module';
import { SharedModule } from './shared/shared.module';
import { IndividualComponent } from './features/services/individual.component';
import { CompaniesComponent } from './features/services/companies.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CourseDetailComponent } from './features/courses/course-detail.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ButtonModule } from 'primeng/button';
import { MenuModule } from 'primeng/menu';
import { CarouselModule } from 'primeng/carousel';
import { ChipModule } from 'primeng/chip';
import { PaymentComponent } from './features/payment/payment.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatSidenavModule } from '@angular/material/sidenav';
import { LoadingService } from './core/services/LoadingService.service';
import { LoadingInterceptor } from './interceptors/LoadingInterceptor.interceptor';
import { SpinnerContainerComponent } from './shared/components/spinner-container/spinner-container.component';

@NgModule({
  declarations: [
    AppComponent,
    CoursesComponent,
    HomeComponent,
    ContactComponent,
    AboutComponent,
    IndividualComponent,
    CompaniesComponent,
    CourseDetailComponent,
    PaymentComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    SharedModule,
    MaterialModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    NgbModule,
    CarouselModule,
    ButtonModule,
    ChipModule,
    FlexLayoutModule,
    MatSidenavModule,
    SpinnerContainerComponent
  ],
  providers: [provideAnimationsAsync(),
    LoadingService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoadingInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
