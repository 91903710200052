<!-- Page Header Start -->
<div class="page-header container-fluid bg-primary d-flex flex-column align-items-center justify-content-center text-center p-5">
  <h1 class="display-3 text-uppercase mb-3">Soluciones Corporativas</h1>
  <div class="d-inline-flex text-white">
    <h6 class="text-uppercase m-0"><a class="text-white" href="index.html">Inicio</a></h6>
    <h6 class="m-0 px-3">/</h6>
    <h6 class="text-uppercase m-0"><a routerLink="/services" style="color: #222429;">Servicios</a></h6>
    <h6 class="m-0 px-3">/</h6>
    <h6 class="text-uppercase m-0">Soluciones Corporativas</h6>
  </div>
</div>
<!-- Page Header Start -->


<!-- Portfolio Start -->
<div class="container-fluid py-1 mb-5">
  <div class="container pt-5 pb-3">
      <hr>
      <p class="mb-4" style="font-size: xx-large; text-align: center;"><b>Emprendedores</b></p>
      <p class="mb-4" style="font-size: x-large; text-align: center;">Te ayudamos a ordenar las finanzas de tu emprendimiento para potenciar su crecimiento, optimizar los rendimientos y tener un acompañamiento que te ayudará a ganar en tranquilidad!.</p>
      <hr>
      <p class="mb-4" style="font-size: xx-large; text-align: center;"><b>PyMEs</b></p>
      <p class="mb-4" style="font-size: x-large; text-align: center;">Nuestro equipo te ayudará a ordenar las finanzas de tu compañía, valuar proyectos y acceder a herramientas exclusivas para PyMEs en el mercado de capitales para financiarse de la forma más conveniente.</p>
      <hr>
  </div>