import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, QueryList, ViewChildren } from '@angular/core';
import { MatMenu } from '@angular/material/menu';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
})
export class HeaderComponent {
  isMobileView = false;

  menus: Menu[] = [
    {
      path: '/',
      label: 'Inicio',
      showOnMobile: false,
      showOnTablet: false,
      showOnDesktop: true,
    },
    {
      path: '/about',
      label: 'Sobre nosotros',
      showOnMobile: false,
      showOnTablet: false,
      showOnDesktop: true,
    },
    {
      path: '/courses',
      label: 'Cursos',
      showOnMobile: false,
      showOnTablet: false,
      showOnDesktop: true,
    },
    {
      label: 'Servicios',
      showOnMobile: false,
      showOnTablet: false,
      showOnDesktop: true,
      child: [
        {
          path: '/services/individual',
          label: 'Individuos',
          showOnMobile: true,
          showOnTablet: true,
          showOnDesktop: true,
        },
        {
          path: '/services/companies',
          label: 'Empresas',
          showOnMobile: true,
          showOnTablet: true,
          showOnDesktop: true,
        },
      ],
    },
    {
      path: '/contact',
      label: 'Contacto',
      showOnMobile: false,
      showOnTablet: false,
      showOnDesktop: true,
    },
  ];

  @ViewChildren('dropSubMenuMobile') subMenuRefs!: QueryList<MatMenu>;

  constructor(private breakpointObserver: BreakpointObserver) {
    this.breakpointObserver.observe([Breakpoints.Handset]).subscribe(result => {
      this.isMobileView = result.matches;
    });
  }

  trackByFn(index: number, item: any): number {
    return index; // o item.id si tienes un ID único para cada item
  }
}

export interface Menu {
  path?: string;
  label: string;
  menu?: string;
  child?: Menu[];
  hash?: string;
  showOnMobile?: boolean;
  showOnTablet?: boolean;
  showOnDesktop?: boolean;
}
