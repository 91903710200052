import { Component } from '@angular/core';

@Component({
  selector: 'app-team',
  templateUrl: './team.component.html',
  styleUrls: ['./team.component.css']
})
export class TeamComponent {

  preventDefault(event: Event) {
    event.preventDefault();
  }
  
  teamMembers = [
    {
      image: 'assets/img/team/am.jpg',
      name: 'Ing. Andres Mirra',
      description: 'Asesor Financiero Independiente (Agente Productor CNV 1995), Ing. Industrial (UTN) y Operador de Mercado de Capitales (IAMC) con amplia experiencia en el mercado de capitales.',
      position1: 'Co-Fundador',
      position2: 'Asesor financiero'
    },
    {
      image: 'assets/img/team/ad.jpg',
      name: 'Lic. Anyelen Dilaj',
      description: 'Operadora de Mercado de Capitales  (IAMC) con amplia experiencia y formación en finanzas, idónea en mercado de capitales (CNV)',
      position1: 'Co-Fundadora',
      position2: 'Gestión de proyectos'
    },
    {
      image: 'assets/img/team/fg.jpg',
      name: 'Florencia Gibellino',
      description: 'Community Manager Freelance con formación en Diseño Digital y Redes Sociales',
      position1: 'Diseño digital',
      position2: 'Community Manager'
    }
  ];
  
  chunkArray(myArray: any[], chunk_size: number): any[][] {
    let results = [];
    
    while (myArray.length) {
        results.push(myArray.splice(0, chunk_size));
    }
    
    return results;
  }
}
