import { Component } from '@angular/core';
import { ICourseDetailDTO } from '../../core/interfaces/course-detail.interface';
import { CoursesService } from '../../core/services/courses.service';
import { ActivatedRoute } from '@angular/router';
import { ICoursePaymentDTO } from '../../core/interfaces/course-payment.interface';
import { PaymentService } from '../../core/services/payment.service';
import { environment } from '../../../environments/environment.dev';
import { loadMercadoPago } from "@mercadopago/sdk-js";
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

declare var MercadoPago: any; // Declarar MercadoPago

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrl: './payment.component.css'
})
export class PaymentComponent {
  course: ICoursePaymentDTO;
  showPayButton = true; 
  paymentForm: FormGroup;
  
  constructor(
    private coursesService: CoursesService,
    private activatedRoute: ActivatedRoute,
    private paymentService: PaymentService,
    private fb: FormBuilder,
  ) {}

  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe((params) => {
      const id = Number(params.get('id'));
      if (id) {
        this.loadCourse(id);
      }
    });
    this.paymentForm = this.fb.group({
      name: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
    });
  }
  
  private loadCourse(id: number) {
    this.coursesService.getCourseByIdPayment(id).subscribe((course) => {
      this.course = course;
    });
  }
  pay(courseId: number) {
    // Verificar si el formulario es válido
    if (this.paymentForm.valid) {
      this.paymentService.createPreference(courseId).subscribe(response => {
        this.createCheckoutButton(response.id);
  
        const formData = this.paymentForm.value;
        const extendedFormData = {
          ...formData,
          courseId: this.course.id,
          courseTitle: this.course.title,
        };
        sessionStorage.setItem('paymentData', JSON.stringify(extendedFormData));
      });
    } else {
      console.error('Por favor complete todos los campos requeridos.');
    }
  }  
  

  private async createCheckoutButton(preferenceId: string) {
    try {
      await loadMercadoPago();
      const mp = new MercadoPago(environment.mercadopagoPublicKey, {
        locale: 'es-AR',
      });
  
      mp.bricks().create("wallet", "wallet_container", {
        initialization: {
          preferenceId: preferenceId,
        },
        
      }).then(() => {
        this.showPayButton = false;
      }).catch((error: any) => {
        console.error('Error creando el botón de pago:', error);
      });
    } catch (error) {
      console.error('Error inicializando MercadoPago:', error);
    }
  }  
}
