import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ContactService } from '../../../core/services/contact.service';

@Component({
  selector: 'app-payment-result',
  standalone: true,
  imports: [
    CommonModule,
  ],
  templateUrl: './payment-result.component.html',
  styleUrl: './payment-result.component.css',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaymentResultComponent implements OnInit { 
  merchantOrderId: string | null = '';
  isError: boolean = false;
  paymentData: any;

  constructor(private route: ActivatedRoute, private contactService: ContactService) {}

  ngOnInit(): void {

    this.merchantOrderId = this.route.snapshot.queryParamMap.get('merchant_order_id');
    const storedData = sessionStorage.getItem('paymentData');
    this.paymentData = storedData ? JSON.parse(storedData) : {};

    if (this.merchantOrderId && this.paymentData) {
      this.sendMailPaymentContact();
    }

    if (!this.merchantOrderId) {
      this.isError = true;
    }
  }
  
  private sendMailPaymentContact(): void {
    const data = {
      merchantOrderId: this.merchantOrderId,
      paymentData: this.paymentData
    };

    // Llamar al servicio para enviar el email
    this.contactService.sendMailPaymentContact(data).subscribe({
      next: (response) => {
        console.log('Email enviado con éxito:', response);
        sessionStorage.removeItem('paymentData');
      },
      error: (error) => {
        console.error('Error enviando el email:', error);
        this.isError = true;
      }
    });
  }
}
