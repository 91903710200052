<form [formGroup]="contactForm" (ngSubmit)="onSubmit()">
    <div class="container-fluid py-5 px-0">
      <div class="row mx-0 justify-content-center">
        <div class="col-lg-6 col-md-8 col-sm-10 px-0">
          <div class="contact-form bg-white rounded p-5 shadow-lg">
            <div id="success"></div>
            <div class="form-row">
              <div class="col-md-6">
                <div class="control-group">
                  <label for="name">Nombre: </label>
                  <input
                    type="text"
                    class="form-control bg-light border-0 p-4"
                    id="name"
                    formControlName="name"
                    placeholder="Nombre y Apellido"
                    data-validation-required-message="Please enter your name"
                    autocomplete="name"
                  />
                  <p class="help-block text-danger" *ngIf="contactForm.get('name')?.invalid && contactForm.get('name')?.touched">
                    Please enter your name
                  </p>
                </div>
              </div>
              <div class="col-md-6">
                <div class="control-group">
                  <label for="email">Email: </label>
                  <input
                    id="email"
                    type="email"
                    formControlName="email"
                    class="form-control bg-light border-0 p-4"
                    placeholder="E-mail"
                    data-validation-required-message="Por favor ingrese su E-mail"
                    autocomplete="email"
                  />
                  <p class="help-block text-danger" *ngIf="contactForm.get('email')?.invalid && contactForm.get('email')?.touched">
                    Por favor ingrese su E-mail
                  </p>
                </div>
              </div>
            </div>
            <div class="control-group">
              <label for="options">Asunto: </label>
              <select
                class="form-select bg-light border-0 p-3 w-100"
                id="options"
                formControlName="subject"
                aria-label="Default select example"
                data-validation-required-message="Por favor seleccione una opción"
                autocomplete="off"
              >
                <option value="" disabled selected>
                  Seleccione una opción
                </option>
                @for (option of subjectOptions; track $index) {
                  <option [value]="option.text">{{option.text}}</option>
                }
              </select>
              <p class="help-block text-danger" *ngIf="contactForm.get('options')?.invalid && contactForm.get('options')?.touched">
                Por favor seleccione una opción
              </p>
            </div>
            <div class="control-group">
              <label for="comment">Comentario: </label>
              <textarea
                id="comment"
                formControlName="comment"
                class="form-control bg-light border-0 py-3 px-4"
                rows="5"
                placeholder="Mensaje"
                data-validation-required-message="Por favor ingrese mensaje"
                autocomplete="off"
              ></textarea>
              <p class="help-block text-danger" *ngIf="contactForm.get('comment')?.invalid && contactForm.get('comment')?.touched">
                Por favor ingrese mensaje
              </p>
            </div>
            <div class="text-center">
              <button
                class="btn btn-primary text-uppercase py-3 px-5"
                type="submit"
                [disabled]="!contactForm.valid || isSubmitting" 
                id="sendMessageButton"
              >
                Enviar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>